import React from "react"
import "../../assets/styles/index.scss"
import Footer from "./footer"
import Header from "./header"
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const Layout = ({ children, active }) => {
  // const props = {
  //   async: true,
  //   defer: true,
  // }
  return (
    // <GoogleReCaptchaProvider
    //   scriptProps={props}
    //   reCaptchaKey="6Lf6fTMcAAAAABuntRZZHVWIcgKZOPmoV_ee6yvk"
    // >
    <div className="main-container">
      <Header active={active} />
      <div className="content-section">{children}</div>
      <Footer />
    </div>
    // </GoogleReCaptchaProvider>
  )
}

export default Layout
