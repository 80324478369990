import React, { useCallback } from "react"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik } from "formik"
// import Swal from "sweetalert2"
import "../../assets/styles/footer.scss"
import { Link } from "gatsby"
import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  // const { executeRecaptcha } = useGoogleReCaptcha()
  // const processReCaptcha = useCallback(async () => {
  //   try {
  //     if (!executeRecaptcha) {
  //       console.log("Execute recaptcha not yet available")
  //       return null
  //     }

  //     const token = await executeRecaptcha("submitForm")
  //     console.log(token)
  //     return token || null
  //   } catch (e) {
  //     return null
  //   }
  // }, [executeRecaptcha])

  const sendSubscribe = async (values, setSubmitting, setFieldValue) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(values.email)) {
      // return Swal.fire({
      //   icon: "error",
      //   text: "Please enter valid email",
      //   confirmButtonColor: "#30855c",
      //   timer: 3000,
      // })
      alert("Please enter valid email")
    }

    // const token = await processReCaptcha()

    // if (!token) {
    //   // return Swal.fire({
    //   //   icon: "error",
    //   //   text: "ReCaptcha verification failed",
    //   //   confirmButtonColor: "#30855c",
    //   //   timer: 3000,
    //   // })
    //   alert("ReCaptcha verification failed")
    // }

    axios
      .post(`${process.env.API_URL}/leads/email-subscribe`, {
        email: values.email,
        // recaptcha: token,
      })
      .then(response => {
        if (response.data?.data?.result === "success") {
          // Swal.fire({
          //   icon: "success",
          //   text: "Success",
          //   confirmButtonColor: "#30855c",
          //   timer: 3000,
          // })
          alert("Success")
        } else {
          // Swal.fire({
          //   icon: "error",
          //   text: response.data?.data?.msg?.includes("already subscribed")
          //     ? "You are already subscribed."
          //     : "Error",
          //   confirmButtonColor: "#30855c",
          //   timer: 3000,
          // })
          alert(
            response.data?.data?.msg?.includes("already subscribed")
              ? "You are already subscribed."
              : "Error"
          )
        }
        setSubmitting(false)
        setFieldValue("email", "")
      })
      .catch(() => {
        // Swal.fire({
        //   icon: "error",
        //   text: "Error",
        //   confirmButtonColor: "#30855c",
        //   timer: 3000,
        // })
        alert("Error")
        setSubmitting(false)
      })
  }
  return (
    <>
      <footer className="text-center text-lg-start bg-clr54 ">
        <section className="pt-1">
          <div className="container text-center text-md-start mt-md-5">
            <div className="d-md-flex justify-content-between mt-3">
              <div className="col-md-5 mb-4">
                <Link to="/">
                  <StaticImage
                    src="../../assets/images/WebsiteFooterLogo.svg"
                    alt="actuate-minds-logo"
                    className="mb-3"
                  />
                </Link>

                <div className="mb-4 d-lg-none">
                  <div className="mb-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none"
                      to="/our-program/"
                    >
                      Our Program
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none"
                      to="/blog/"
                    >
                      Blogs
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none"
                      to="/privacy-policy/"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none mb-md-3"
                      to="/terms-conditions/"
                    >
                      Terms & Conditions
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none mb-md-3"
                      to="/contact-us/"
                    >
                      Contact
                    </Link>
                  </div>
                  <div>
                    <a
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none mb-md-3"
                      href="https://www.softobotics.com/"
                      alt="Softobotics: Technical Partner"
                    >
                      Developed By
                    </a>
                  </div>
                </div>

                <div className="pt-md-2 pb-md-5 footer-icon-sm">
                  <a
                    href="https://www.facebook.com/actuateminds"
                    target="_blank"
                    rel="noreferrer"
                    className="me-xl-4 text-reset"
                  >
                    <StaticImage
                      src="../../assets/images/Facebook.svg"
                      alt="Facebook"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/actuateminds/"
                    target="_blank"
                    rel="noreferrer"
                    className="me-xl-4 text-reset"
                  >
                    <StaticImage
                      src="../../assets/images/Instagram.svg"
                      alt="Instagram"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/actuate-minds"
                    target="_blank"
                    rel="noreferrer"
                    className="me-xl-4 text-reset"
                  >
                    <StaticImage
                      src="../../assets/images/Linkedin.svg"
                      alt="Linkedin"
                    />
                  </a>
                  <a
                    href="https://twitter.com/actuateminds"
                    target="_blank"
                    rel="noreferrer"
                    className="me-xl-4 text-reset"
                  >
                    <StaticImage
                      src="../../assets/images/Twitter.svg"
                      alt="Twitter"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOpqCtxiFJfJxgrllklXElg"
                    rel="noreferrer"
                    target="_blank"
                    className="me-xl-4 text-reset"
                  >
                    <StaticImage
                      src="../../assets/images/Youtube.svg"
                      alt="Youtube"
                    />
                  </a>
                </div>
                <p className="fnt-md-22 fnt-lg-22 fnt-lg-600 text-white d-none d-lg-block">
                  Get handpicked knowledge and insights about the evolving world
                  of education every week.
                </p>
                <p className="fnt-16 fnt-lg-18 text-white d-lg-none fnt-sm-825">
                  <i>
                    Get handpicked knowledge and insights about the evolving
                    world of education every week.
                  </i>
                </p>
                <div className="email-letter">
                  <Formik
                    initialValues={{ email: "" }}
                    validate={values => {
                      const errors = {}
                      if (!values.email) {
                        errors.email = "Required"
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid Email"
                      }
                      return errors
                    }}
                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                      sendSubscribe(values, setSubmitting, setFieldValue)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter email"
                          />
                          <div className="error">
                            {errors.email && touched.email && errors.email}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btndefault greenbtn"
                          disabled={isSubmitting}
                        >
                          Subscribe
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="d-md-flex">
                <div className="mx-md-5 pr-lx-5 mb-4 d-none d-lg-block">
                  <h6 className="fnt-md-28 fnt-lg-28 fnt-700 text-white mb-4">
                    Site Map
                  </h6>
                  <div className="mb-md-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none"
                      to="/our-program/"
                    >
                      Our Program
                    </Link>
                  </div>
                  <div className="mb-md-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none"
                      to="/blog/"
                    >
                      Blogs
                    </Link>
                  </div>
                  <div className="mb-md-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none"
                      to="/privacy-policy/"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="mb-md-3">
                    <Link
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none mb-md-3"
                      to="/terms-conditions/"
                    >
                      Terms & Conditions
                    </Link>
                  </div>
                  <div>
                    <a
                      className="fnt-md-20 fnt-lg-22 fnt-500 text-white text-decoration-none mb-md-3"
                      href="https://www.tools.softobotics.com/"
                      alt="Online Tools"
                    >
                      Online Tools
                    </a>
                  </div>
                </div>

                <div className="mb-md-0">
                  <h6 className="fnt-md-28 fnt-lg-28 fnt-700 text-white mb-4 d-none d-lg-block">
                    Contact
                  </h6>
                  <p className="fnt-md-20 fnt-lg-22 fnt-500 text-white">
                    <StaticImage
                      className="d-lg-none me-3"
                      src="../../assets/images/Contact-icon.svg"
                      alt="contact"
                    />
                    +91 8147594806
                  </p>
                  <p className="fnt-md-20 fnt-lg-22 fnt-500 text-white">
                    <StaticImage
                      className="d-lg-none me-3"
                      src="../../assets/images/Mail-icon.svg"
                      alt="actuateminds-email"
                    />
                    info@actuateminds.com
                  </p>
                  <p className="fnt-md-20 fnt-lg-22 fnt-500 text-white">
                    <StaticImage
                      className="d-lg-none me-3"
                      src="../../assets/images/Location-icon.svg"
                      alt="softobotics address"
                    />
                    Bangalore, India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-4 fnt-md-22 fnt-lg-22 text-white copyright">
          Copyright © 2021 &nbsp;
          <a className="text-reset" href="https://www.actuateminds.com/">
            Actuate Minds
          </a>
        </div>
      </footer>
    </>
  )
}

export default Footer
